<template>
	<div>
		<b-row>
			<b-col lg="4">
				<b-overlay :show="isLoading">
					<div class="d-flex">
						<b-card class="p-2 container" no-body>
							<div class="d-flex p-1">
								<h3 class="text-uppercase">CONDICIONES DE CAJA CHICA</h3>
							</div>
							<p>
								Los registros de
								<span class="font-weight-bolder">caja chica</span>
								que cumplan estas condiciones, se emitira una alerta.
							</p>
							<b-row class="mb-2">
								<b-col cols="12">
									<div class="d-flex justify-content-center">
										<b-form-group
											:class="{
												'w-75': ['xs', 'sm'].includes(breakpoint),
												'w-75': ['md', 'lg'].includes(breakpoint),
												'w-50': ['xl'].includes(breakpoint),
											}"
										>
											<label for="balance_min">
												Balance minimo
												<span class="text-success" style="font-weight: bold">(S/.)</span>
											</label>
											<vue-number-input
												id="balance_min"
												center
												vertical
												controls
												v-model="balance.balance_min"
												:min="20"
												:max="10000"
											/>
										</b-form-group>
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right">
									<b-button size="lg" variant="success" @click="updateBalanceSetting">
										Guardar
									</b-button>
								</b-col>
							</b-row>
						</b-card>
					</div>
				</b-overlay>
			</b-col>
			<b-col lg="8">
				<b-card class="p-2 container" no-body>
					<div class="d-flex justify-content-between p-1">
						<h3 class="text-uppercase">CATEGORIAS Y SUBCATEGORIAS</h3>
						<b-button variant="success" class="btn-icon btn-sm">
							<feather-icon icon="PlusIcon" class="" size="20" @click="addCategory" />
						</b-button>
					</div>

					<div>
						<b-table-simple responsive sticky-header="50vh" no-border-collapse>
							<b-thead class="">
								<b-th>Categoria</b-th>
								<b-th>Subcategoria</b-th>
								<b-th class="text-center">Acciones</b-th>
							</b-thead>
							<b-tbody>
								<template v-for="(desc, index) in descriptions">
									<b-tr :key="index">
										<b-td>
											<div>{{ desc.category }}</div>
										</b-td>
										<b-td>
											<div>{{ desc.name }}</div>
										</b-td>
										<b-td>
											<div class="d-flex justify-content-center">
												<feather-icon
													icon="EditIcon"
													size="18"
													class="text-warning cursor-pointer"
													@click="editDescription(desc)"
												/>
											</div>
										</b-td>
									</b-tr>
								</template>
							</b-tbody>
						</b-table-simple>
					</div>
				</b-card>
			</b-col>
		</b-row>

		<DescriptionModal
			v-if="descriptionModal"
			:info="descriptionSelected"
			:origin="origin"
			@close="descriptionModal = false"
			@refresh=";(descriptionModal = false), getDescriptionsOfTransactions()"
		/>
	</div>
</template>

<script>
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import DescriptionModal from "@/views/amg/management/views/petty-cash/components/DescriptionPettyCashModal.vue"
export default {
	components: {
		DescriptionModal,
	},
	props: {},
	data() {
		return {
			isLoading: false,
			balance: { id: null, balance_min: 20 },
			descriptions: [],
			descriptionModal: false,
			descriptionSelected: {},
			origin: "",
		}
	},
	computed: {
		breakpoint() {
			return this.$store.getters["app/currentBreakPoint"]
		},
	},
	async created() {
		this.isPreloading()
		try {
			await Promise.all([this.getDescriptionsOfTransactions(), this.getSettingBalance()])
		} catch (error) {
			console.log(error)
		} finally {
			this.isPreloading(false)
		}
	},
	methods: {
		async getDescriptionsOfTransactions() {
			this.isPreloading()
			try {
				const { data } = await PettyCashService.getDescriptionsOfTransactions()
				this.descriptions = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async getSettingBalance() {
			try {
				const { data } = await PettyCashService.getSettingBalance()
				this.balance = data
			} catch (error) {
				console.log(error)
			}
		},

		async updateBalanceSetting() {
			this.isPreloading()
			try {
				const { data } = await PettyCashService.updateSettingBalance({
					id: this.balance.id,
					balance_min: this.balance.balance_min,
				})
				this.showSuccessToast("Balance minimo actualizado")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		addCategory() {
			this.descriptionModal = true
			this.descriptionSelected = {}
			this.origin = "petty-cash"
		},

		editDescription(item) {
			this.descriptionModal = true
			this.descriptionSelected = item
			this.origin = "brain"
		},
	},
}
</script>
